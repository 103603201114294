import React from 'react'
import InstitucionesTable from './components/InstitucionesTable'

export default function index() {
    return (
        <div className="mt-1 flex flex-col items-start">

            <InstitucionesTable />
        </div>
    )
}
