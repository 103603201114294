import { Input, Table, Tag } from "antd";
import useUsers from "hooks/useUsers";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { routesNames } from "utils/Constants";
import { getCurriculumPDF } from "services/Usuarios";
import useNotification from "hooks/useNotification";
import { FaFilePdf } from "react-icons/fa";
import { BiSearchAlt } from "react-icons/bi";
import { BASE_API } from "config/config";
import ExportExcel from "components/excel/ExportExcel";
import dayjs from "dayjs";

const UsersTable = () => {
    const [value, setValue] = useState("");
    const [datasource, setDatasource] = useState([]);
    const { contextHolder, openNotification } = useNotification();
    const { isLoading, response, fetchData } = useUsers();
    const [dataExcel, setDataExcel] = useState([]);

    const fetchUsers = async () => {
        const data = await fetchData(null, true);
        console.log(data)

        setDatasource(data);
        dataExport(data);
    };

    useEffect(() => {
        fetchUsers();
    }, []);

    const fetchPdf = async (userId) => {
        const data = await getCurriculumPDF(userId);
        if (data?.uuid) {
            window.open(`${BASE_API}cv/${data.filename}`, "_blank", "noreferrer");
        } else {
            openNotification("El curriculum se encuentra incompleto", "success", "Atención!");
        }
    };

    const dataExport = (data) => {
        const exportData = data.map((item) => {
            return {
                Perfil: item.type === 'I' ? 'Institución' : item.type === 'P' ? 'Postulante' : 'Administrador',
                Nombre: item.name,
                Email: item.email,
                Creado: dayjs(item.created_at).format('DD/MM/YYYY'),
                Plan: item.plan?.detalle?.nombre,
                Estado: item.cuenta?.rechazado ? "Rechazado" : item.cuenta?.suspendido ? "Suspendido" : item.cuenta?.activo ? "Activo" : "Inactivo",
            }
        })

        setDataExcel(exportData)
    }

    const columns = [
        // Aquí están todas las columnas que ya tienes definidas
        {
            title: "Perfil",
            dataIndex: "type",
            key: "type",
            filters: [
                { text: "Institución", value: "I" },
                { text: "Postulante", value: "P" },
                { text: "Administrador", value: "A" },
            ],
            onFilter: (value, record) => record.type === value,
            render: (text) => {
                if (text === "I") return <Tag color="lime">Institución</Tag>;
                if (text === "P") return <Tag color="blue">Postulante</Tag>;
                return <Tag color="orange">Administrador</Tag>;
            },
        },
        {
            title: "Nombre",
            dataIndex: "nombre",
            key: "nombre",
            render: (text, record) => (record.type === "I" ? record.cuenta.nombre : record.name),
        },
        {
            title: "Email",
            dataIndex: "email",
        },
        {
            title: "Creado",
            dataIndex: "created_at",
            render: (text) => new Date(text).toLocaleDateString("es-ES", { day: "2-digit", month: "2-digit", year: "numeric" }),
            sorter: (a, b) => new Date(a.created_at) - new Date(b.created_at),
        },
        {
            title: "Plan",
            dataIndex: "plan",
            render: (text, record) => {
                if (record?.type === "P" || record?.type === "A") return null;
                if (!record?.plan) return <Tag color="purple">STANDARD</Tag>;
                return (
                    <Tag color={record?.plan.plan_id === 1 ? "gold" : "purple"}>
                        {record.plan.detalle.nombre.toUpperCase()}
                    </Tag>
                );
            },
            filters: [
                { text: "STANDARD", value: "Standard" },
                { text: "GOLD", value: "Gold" },
            ],
            onFilter: (value, record) => record.plan?.detalle?.nombre === value,
        },
        {
            title: "Verificado",
            dataIndex: "verificado",
            render: (text, record) => (
                <Tag color={record.cuenta?.verificado === 0 ? "red" : "green"}>
                    {record.cuenta?.verificado === 0 ? "Pendiente" : "Verificado"}
                </Tag>
            ),
            filters: [
                { text: "Pendiente", value: 0 },
                { text: "Verificado", value: 1 },
            ],
            onFilter: (value, record) => record.cuenta?.verificado === value,
        },
        {
            title: "Estado",
            dataIndex: "estado",
            render: (text, record) => {
                if (record.cuenta?.rechazado) return <Tag color="red">Rechazado</Tag>;
                if (record.cuenta?.suspendido) return <Tag color="red">Suspendido</Tag>;
                return <Tag color={record.cuenta?.activo ? "green" : "red"}>{record.cuenta?.activo ? "Activo" : "Inactivo"}</Tag>;
            },
        },
        {
            title: "IA",
            dataIndex: "disponible_ia",
            render: (text, record) => (
                <Tag color={record.cuenta?.disponible_ia === -1 ? "green" : "red"}>
                    {record.cuenta?.disponible_ia === -1 ? "Si" : "No"}
                </Tag>
            ),
            filters: [
                { text: "Ver", value: -1 },
            ],
            onFilter: (value, record) => record.cuenta?.disponible_ia === value,
        },
        {
            title: "CV",
            dataIndex: "fecha_perfil_completo",
            render: (text, record) => (
                // console.log(record)
                record?.type === "P" &&
                < Tag color={record?.cuenta?.fecha_perfil_completo !== null ? "green" : "red"} >
                    {record?.cuenta?.fecha_perfil_completo !== null ? "Completo" : "Incompleto"}
                </Tag >
            ),
            filters: [
                { text: "Completo", value: "completo" },
                { text: "Incompleto", value: "incompleto" },
            ],
            onFilter: (value, record) => {
                if (record?.type === "P") {
                    if (value === "completo") {
                        return record?.cuenta?.fecha_perfil_completo !== null;
                    }

                    if (value === "incompleto") {
                        return record?.cuenta?.fecha_perfil_completo === null;
                    }

                    return false;
                }

            },
        },
        {
            title: "Acciones",
            dataIndex: "detalle",
            render: (text, record) => (
                <div className="flex items-center gap-1">
                    <Link to={`${routesNames.USER}/${record.id}`} className="bg-main text-sm px-4 py-1 rounded-lg">
                        Ver
                    </Link>
                    {record?.type === "P" && (
                        record?.cuenta?.fecha_perfil_completo !== null &&
                        <a onClick={() => fetchPdf(record?.id)} className="bg-red-500 text-xs text-white px-2 rounded-md py-1 flex items-center">
                            <FaFilePdf className="text-white" /> Descargar
                        </a>
                    )}
                </div>
            ),
        },
    ];


    return (
        <>
            {contextHolder}
            <Input
                allowClear
                placeholder="Buscar por Nombre, Email, Plan o Tipo de usuario"
                prefix={<BiSearchAlt className="text-xl" />}
                value={value}
                className="p-3 w-full rounded-lg mb-2"
                onChange={(e) => {
                    const currValue = e.target.value.toLowerCase();
                    setValue(currValue);
                    const filteredData = response.filter((entry) =>
                        entry.cuenta?.nombre?.toLowerCase().includes(currValue) ||
                        entry.name.toLowerCase().includes(currValue) ||
                        entry.email.toLowerCase().includes(currValue) ||
                        entry.type_d.toLowerCase().includes(currValue) ||
                        entry.plan?.detalle?.nombre.toLowerCase().includes(currValue)
                    );
                    setDatasource(filteredData);
                }}
            />

            <div className="flex flex-row justify-between items-center mb-3">
                <span className="block text-sm font-semibold text-start my-1 me-5">
                    {datasource?.length} usuarios encontrados
                </span>
                <ExportExcel data={dataExcel} fileName={"Usuarios_" + new Date().toLocaleDateString()} />
            </div>


            <Table
                loading={isLoading}
                dataSource={datasource}
                columns={columns}
                className="w-full"
                rowKey={(record) => record.id}
                size="small"
                pagination={{ pageSize: 20, showSizeChanger: false }}
                locale={{ emptyText: "No hay resultados" }}
                onChange={(pagination, filters, sorter, extra) => {
                    dataExport(extra.currentDataSource)
                }}
            />

        </>
    );
};

export default UsersTable;
