export const routesNames = {
    INDEX: '/',
    LOGIN: '/login',
    DASHBOARD: '/admin/dashboard',
    PUBLICATIONS: '/admin/publications',
    PUBLICATION: '/admin/publication',
    PUBLICATION_EDIT: '/admin/publication/edit',
    POSTULATIONS: '/admin/postulations',
    PLAN: '/admin/plan',
    PLANS_REQUEST: '/admin/plans',
    REQUESTS: '/admin/marketplace',
    CONTABLE: '/admin/contable',
    POSTULANTES: '/admin/users/postulantes',
    INSTITUCIONES: '/admin/users/instituciones',
    USERS: '/admin/users',
    USER: '/admin/user',
    ESPECIALIDADES: '/admin/specialties',
    CMS: '/admin/cms',
    FAQS: '/admin/cms/faqs',
    TERMS: '/admin/cms/terms',
    PAGES: '/admin/cms/pages',
    BLOCKS: '/admin/cms/blocks',
    CONTACT: '/admin/cms/contact',
    TRANSFER_DATA: '/admin/settings/data',
    PLANS: '/admin/cms/plans',
    SETTINGS: '/admin/settings',
    SETTINGS_PUBLICATIONS: '/admin/settings/publications',
    SETTINGS_PLANS: '/admin/settings/plans',
    NOTIFICATIONS_ADMIN: '/admin/notifications/administrador',
    NOTIFICATIONS_INST: '/admin/notifications/institucion',
    NOTIFICATIONS_USER: '/admin/notifications/postulante',
    NOTIFICATIONS_CUSTOM: '/admin/notifications/custom',
    REPORTS_DEMOGRAPHY: '/admin/reports/demography',
    REPORTS_TECHNOLOGIES: '/admin/reports/technologies',
    REPORTS_CONTINUOUS_TRAINING: '/admin/reports/continuous-training',
    REPORTS_INSTITUTIONS: '/admin/reports/institutions_reports',
}

export const estadoPublicacion = {
    PENDIENTE_PAGO: 1,
    PENDIENTE_APROBACION: 2,
    APROBADO: 3,
    CANCELADO: 4,
    REEMBOLSADO: 5,
    VENCIDO: 6
}