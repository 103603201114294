import React from 'react'
import PostulantesTable from './components/PostulantesTable'

export default function index() {
    return (
        <div className="mt-1 flex flex-col items-start">

            <PostulantesTable />
        </div>
    )
}
