import { useEffect, useState } from "react";
import { getInstituciones } from "services/Reports";
import { Table } from "antd";
import ExportExcel from "components/excel/ExportExcel";
import Loader from "components/loader";
import dayjs from "dayjs";

const InstitutionsReports = () => {
  const [instituciones, setInstituciones] = useState([]);
  const [dataExcel, setDataExcel] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchFormacionContinua = async () => {
    try {
      setIsLoading(true)
      const data = await getInstituciones()
      setIsLoading(false)

      if (data) {
        setInstituciones(data[0]);
        dataExport(data[0])
      }

    } catch (error) {
      return error
    }
  }

  const columns = [
    {
      title: 'User_ID',
      dataIndex: 'user_id',
    },
    {
      title: 'Username',
      dataIndex: 'name',
      render: (text, record) => <>{record.user.name}</>
    },
    {
      title: 'Email',
      dataIndex: 'email',
      render: (text, record) => <>{record.user.email}</>
    },
    {
      title: 'Nombre',
      dataIndex: 'nombre',
    },
    {
      title: 'Plan',
      dataIndex: 'plan',
      render: (text, record) => <>{record.user?.plan?.detalle?.nombre}</>,
      filters: [
        {
          text: 'Gold',
          value: 'Gold',
        },
        {
          text: 'Standard',
          value: 'Standard',
        },
      ],
      onFilter: (value, record) => record.user?.plan?.detalle?.nombre === value
    },
    {
      title: 'Ciudad',
      dataIndex: 'localidad',
    },
    {
      title: 'Provincia',
      dataIndex: 'provincia',
    },
    {
      title: 'Antiguedad en la plataforma',
      dataIndex: 'created_at',
      render: (text) => <>{dayjs(text).format('DD/MM/YYYY')}</>,
      sorter: (a, b) => dayjs(a.created_at).unix() - dayjs(b.created_at).unix(),
    },
    {
      title: 'Última actualización',
      dataIndex: 'updated_at',
      render: (text) => <>{dayjs(text).format('DD/MM/YYYY')}</>,
      sorter: (a, b) => dayjs(a.updated_at).unix() - dayjs(b.updated_at).unix(),
    },
    {
      title: 'Publicaciones',
      dataIndex: 'publicaciones_count',
      sorter: (a, b) => a.publicaciones_count - b.publicaciones_count
    },
    {
      title: '¿Tiene publicaciones activas?',
      dataIndex: 'publicaciones_activas_count',
      filters: [
        {
          text: 'Si',
          value: 1,
        },
        {
          text: 'No',
          value: 0,
        },
      ],
      onFilter: (value, record) => record.publicaciones_activas_count === value
    },
    {
      title: '¿Qué publicó?',
      dataIndex: 'especialidades_list',
    },
    {
      title: 'Postulantes',
      dataIndex: 'postulaciones_count',
      sorter: (a, b) => a.postulaciones_count - b.postulaciones_count
    },
  ];

  const dataExport = (data) => {
    const exportData = data.map((item) => {
      return {
        'User_ID': item.user_id,
        'Username': item.user.name,
        'Email': item.user.email,
        'Nombre': item.nombre,
        'Plan': item.user?.plan?.detalle?.nombre,
        'Ciudad': item.localidad,
        'Provincia': item.provincia,
        'Antiguedad en la plataforma': dayjs(item.created_at).format('DD/MM/YYYY'),
        'Última actualización': dayjs(item.updated_at).format('DD/MM/YYYY'),
        'Publicaciones': item.publicaciones_count,
        '¿Tiene publicaciones activas?': item.publicaciones_activas_count,
        '¿Qué publicó?': item.especialidades_list,
        'Postulantes': item.postulaciones_count
      }
    })

    setDataExcel(exportData)
  }

  useEffect(() => {
    fetchFormacionContinua()
  }, [])

  return (
    <>
      <div className="flex flex-row gap-5">
        <h1 className="text-3xl font-medium mb-5">/ Instituciones</h1>
        <ExportExcel data={dataExcel} fileName={"Datos_instituciones" + new Date().toLocaleDateString()} title="Descargar Excel" />
      </div>

      {isLoading ? <Loader /> :
        <Table
          columns={columns}
          dataSource={instituciones}
          onChange={(pagination, filters, sorter, extra) => {
            dataExport(extra.currentDataSource)
          }}
        />
      }
    </>
  );
};

export default InstitutionsReports;
